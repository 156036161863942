body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    //     "Droid Sans", "Helvetica Neue", sans-serif;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

.audio-media-player {
    margin-top: 20px;
    &:focus {
        outline: none;
    }
}

.main-loader {
    height: 100vh !important;
    .Loader__background {
        z-index: 9999 !important;
    }
    .Loader__content {
        height: 100vh;
    }
}

.Mui-disabled {
    background-color: #6877ca;
}

.login-container {
    .login-logo-div {
        width: 100%;
        height: 100px;
        img {
            max-width: 80%;
            max-height: 100%;
        }
    }
    .form-container {
        margin-top: 70px;
        .login-button {
            &:disabled {
                background-color: #6877ca !important;
            }
        }
    }
}

.homepage-container {
    .homepage-container-item {
        margin: auto;
        .item-box {
            button {
                margin-top: 15px;
            }
        }
    }
}

.admin-delivered {
    span {
        // border: 1px solid rgb(199, 199, 199);
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        background: #149b04;
    }
}

.view-release-container {
    .status-box {
        border-radius: 5px;

        p {
            position: relative;
            color: black;
            font-size: 16px;
            padding-left: 22px;
            svg {
                top: 2px;
                left: 0;
                position: absolute;
                font-size: 16px;
            }
        }
    }
    .catalog-image {
        .image-div {
            width: 220px;
            // display: block;
            height: 220px;
            text-align: center;
            margin: auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .download-link {
            position: absolute;
            left: 39%;
            margin-top: 5px;
        }
    }
    .item-container {
        label {
            font-size: 14px;
            padding-bottom: 6px;
            font-weight: 600;
        }
        &.apple-id {
            label {
                font-size: 12px;
                // color: #37aacc;
            }
            p {
                font-size: 12px;
                // color: #37aacc;
            }
        }
    }
}

.container-song-view {
    margin-top: 30px;
    P {
        margin-bottom: 10px;
        display: flex;
        label {
            font-weight: 600;
            margin-right: 10px;
            width: 130px;
            display: block;
        }
        span {
        }
    }
    .dim-this-one {
        margin-bottom: 0px;
        font-size: 12px;
        color: grey;
    }
}
.color-white {
    color: white !important;
    padding: 10px !important;
    padding-left: 15px !important;
}

.status-approved {
    margin-top: 44px;
    font-size: 35px;
    text-transform: uppercase;
    color: green;
}

.status-rejected {
    margin-top: 44px;
    font-size: 35px;
    text-transform: uppercase;
    color: red;
}

.aceept-reject-button {
    margin-top: 10px;
    display: flex;
    padding: 0px !important;
    justify-content: space-evenly;
    button {
        border: none;
        outline: none;
        width: 45%;
        padding: 8px 0px;
        border-radius: 3px;
        font-weight: 600;
        cursor: pointer;
        &.accept {
            background-color: #3ca93c;
            color: white;
        }
        &.reject {
            background-color: red;
            color: white;
        }
    }
}

.text-area-input {
    width: 100%;
    resize: none;
    height: 150px;
    font-size: 14px;
    padding: 8px;
    font-family: "Roboto", sans-serif;
}

.text-area-input_news {
    width: 100%;
    resize: none;
    height: 50px;
    font-size: 14px;
    margin: 10%;
    padding: 8px;
    font-family: "Roboto", sans-serif;
}

.reject-single-song {
    width: 75px;
    padding: 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    background: red;
    color: white;
    cursor: pointer;
    font-weight: 600;
}

.unrevoke-action {
    width: 85px;
    padding: 6px;
    border: none;
    color: white;
    background: green;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
}

.revoke-action {
    width: 85px;
    padding: 6px;
    border: none;
    color: white;
    border-radius: 5px;
    background: red;
    cursor: pointer;
    font-weight: 600;
    outline: none;
}

.view-user-details-container {
    .box-container {
        h3 {
            margin-bottom: 20px;
        }
        .details-div {
            // text-align: center;
            // width: fit-content;
            // margin: auto;
            p {
                margin-top: 10px;
                display: flex;
                width: fit-content;
                // margin: auto;
                label {
                    font-weight: 600;
                    min-width: 165px;
                    display: block;
                    // text-align: right;
                    // padding-right: 10px;
                }
            }
        }
    }
}

.release-filter-div {
    width: 200px;
    float: right;
    position: relative;
    .svg {
        position: absolute;
        top: 19%;
        left: -34px;
    }
}

.label-tag {
    padding: 10px 0px !important;
    font-weight: 600 !important;
}

.view-tickets-container {
    .box-div {
        label {
            font-weight: 600;
        }
        p {
        }
    }
    .reply-ticket-input {
        height: 100px;
        // min-width: 100%;
        // max-width: 90%;
        font-family: "Roboto", sans-serif;
        width: 100%;
        border: 1px solid grey;
        outline: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 15px;
        resize: none;
    }
    .svg-div {
        width: 55px;
        height: 100%;
        margin: auto;
        padding-left: 8px;
        display: flex;
        button {
            border-radius: 30px;
            border: none;
            outline: none;
            height: 40px;
            width: 40px;
            background-color: #1da21d;
            color: white;
            cursor: pointer;
            .svg-send {
                margin-top: 3px;
                margin-left: 2px;
            }
        }
    }
}

.MuiPagination-root {
    height: 80px;
    display: flex;
    float: right;

    ul {
        margin: auto;
    }
}

.attachment-div {
    height: 50px;
    background: red;
    width: 100px;
}

.add-revenue-button {
    color: white !important;
    background-color: green !important;
}

.user-details-actions {
    display: flex;
    .download-agreement-btn {
        display: flex;
        margin-right: 5px;
        border: none;
        background: #0ba3ac;
        color: white;
        padding: 3px 6px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        svg {
            font-size: 14px;
            align-self: center;
            margin-right: 5px;
        }
    }
}

.user-details-in-review {
    font-weight: 600;
    text-align: left;
    span {
        font-weight: 400;
    }
}

.settle-payout {
    background: green;
    border: 1px solid green;
    color: white;
    padding: 3px 14px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    &:hover {
        background: white !important;
        color: green !important;
    }
}

.link-color-black {
    color: #000 !important;
}

.save-attachements {
    width: fit-content;
    display: block;
    padding: 25px 6px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid grey;
}

.full-width-tab-panel {
    width: 100%;
    height: calc(100vh - 210px);
}

.ticket-reply-created {
    position: absolute;
    color: grey;
    width: 160px;
    top: -18px;
    font-size: 12px;
    &.right {
        right: -40px;
    }
    &.left {
        left: 0;
    }
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }

  input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: none;
    outline: none;
    width: 45%;
    padding: 6px 0px;
    border-radius: 3px;
    font-weight: 400;
    display: flex;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    background-color: #6877ca;
    color: white;
}